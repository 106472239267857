body {
    margin: 0;
    font-family: 'Open Sans', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow: hidden;
}

body {
    position: fixed;
    width: 100%;
}

code {
    font-family: 'Open Sans', sans-serif;
}

/* .image-gallery-slide {
 

} */

.image-gallery-image {
    border-radius: 20px;
}
